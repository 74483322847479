<template>
  <div class="app-container">
    <div class="filter-container" style="display: flex;flex-wrap: wrap;">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>

      <el-input class="filter-item" v-model="listQuery.name" placeholder="姓名" style="width: 220px;" clearable/>

      <el-input class="filter-item" v-model="listQuery.phone" placeholder="手机号码" style="width: 220px;" clearable/>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>

      <el-button class="filter-item" type="success" @click="downloadTeacherCardTemplate" style="margin-left: 0px;">教师卡模板</el-button>

      <el-upload class="filter-item" accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportTeacherCard">
        <el-button type="warning">导入教师卡</el-button>
      </el-upload>

      <el-button class="filter-item" type="warning" @click="handleTypeRecharge">按类型一键充值</el-button>

      <el-button class="filter-item" type="warning" @click="handleTypeClearBalance" style="margin-left: 0px;">按类型一键清零余额</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="openid" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.openid }}
        </template>
      </el-table-column>

      <el-table-column label="昵称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.nickname }}
        </template>
      </el-table-column>

      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.name }}
        </template>
      </el-table-column>

      <el-table-column label="手机号码" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.user.phone }}
        </template>
      </el-table-column>

      <el-table-column label="类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.teacher_card_type.name }}
        </template>
      </el-table-column>

      <el-table-column label="卡余额" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.balance }}
        </template>
      </el-table-column>

      <el-table-column label="到期时间" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.expire_date }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="success" size="mini" @click="handleTeacherCardOrder(scope.row)">消费记录</el-button>

          <el-button type="warning" size="mini" @click="handleBalanceRechargeRecovery(scope.row, 1)">充值</el-button>

          <el-button type="danger" size="mini" @click="handleBalanceRechargeRecovery(scope.row, 2)">回收</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item v-if="!form.id" label="用户" prop="user_id">
          <el-select v-model="form.user_id" placeholder="输入昵称/手机号/姓名进行搜索" filterable remote :remote-method="userFilter"  :loading="userFilterLoading" style="width:340px" clearable>
            <el-option v-for="item in users" :key="item.id" :label="item.nickname" :value="item.id">
              <div style="display: flex;align-items:center;text-align:center;">
                <img :src="item.avatar" style="width: 26px;height:26px;border-radius:4px;margin-right:4px;">
                <span>{{ item.nickname }} / {{item.phone}} / {{item.name}}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="类型" prop="teacher_card_type_id">
          <el-select filterable v-model="form.teacher_card_type_id" placeholder="请选择类型" clearable>
            <el-option v-for="item in teacherCardTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!form.id" label="充值金额" prop="balance">
          <el-input-number v-model="form.balance" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>

        <el-form-item label="到期时间" prop="expire_date">
          <el-date-picker v-model="form.expire_date" placeholder="请选择日期" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->

    <!-- 导入教师卡 -->
    <el-dialog title="导入数据预览" :visible.sync="importTeacherCardVisible" width="60%" :close-on-click-modal="false">
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="importTeacherCardData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone || "" }}
              </template>
            </el-table-column>

            <el-table-column label="类型" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.teacher_card_type || "" }}
              </template>
            </el-table-column>

            <el-table-column label="充值金额" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.amount || "" }}
              </template>
            </el-table-column>

            <el-table-column label="到期时间" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.expire_date || "" }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importTeacherCardVisible = false">取消</el-button>
        <el-button type="primary" @click="importTeacherCardSaveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导入教师卡 -->

    <!-- 按类型一键充值 -->
    <el-dialog title="按类型一键充值" :visible.sync="dialogVisibleTypeRecharge" width="60%" :close-on-click-modal="false">
      <el-form ref="typeRechargeForm" :rules="typeRechargeRules" :model="typeRechargeForm" label-width="150px">
        <el-form-item label="类型" prop="teacher_card_type_id">
          <el-select filterable v-model="typeRechargeForm.teacher_card_type_id" placeholder="请选择类型" clearable>
            <el-option v-for="item in teacherCardTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="充值金额" prop="amount">
          <el-input-number v-model="typeRechargeForm.amount" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>

        <el-form-item label="到期时间" prop="expire_date">
          <el-date-picker v-model="typeRechargeForm.expire_date" placeholder="请选择日期" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTypeRecharge = false">取消</el-button>
        <el-button type="primary" @click="saveDataTypeRecharge" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 按类型一键充值 -->

    <!-- 按类型一键清零余额 -->
    <el-dialog title="按类型一键清零余额" :visible.sync="dialogVisibleTypeClearBalance" width="60%" :close-on-click-modal="false">
      <el-form ref="typeClearBalanceForm" :rules="typeClearBalanceRules" :model="typeClearBalanceForm" label-width="150px">
        <el-form-item label="类型" prop="teacher_card_type_id">
          <el-select filterable v-model="typeClearBalanceForm.teacher_card_type_id" placeholder="请选择类型" clearable>
            <el-option v-for="item in teacherCardTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTypeClearBalance = false">取消</el-button>
        <el-button type="primary" @click="saveDataTypeClearBalance" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 按类型一键清零余额 -->

    <!-- 余额充值/回收 -->
    <el-dialog title="余额充值/回收" :visible.sync="dialogVisibleBalanceRechargeRecovery" width="60%" :close-on-click-modal="false">
      <el-form ref="balanceRechargeRecoveryForm" :rules="balanceRechargeRecoveryRules" :model="balanceRechargeRecoveryForm" label-width="150px">
        <el-form-item label="操作类型" prop="type">
          <el-radio-group v-model="balanceRechargeRecoveryForm.type" disabled>
            <el-radio :label="1">充值</el-radio>
            <el-radio :label="2">回收</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="操作金额" prop="amount">
          <el-input-number v-model="balanceRechargeRecoveryForm.amount" controls-position="right" :min="0" :precision="2"></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleBalanceRechargeRecovery = false">取消</el-button>
        <el-button type="primary" @click="saveDataBalanceRechargeRecovery" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 余额充值/回收 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import {excel2Json} from "@/utils/excel";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          name: "",
          phone: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          school_id: "",
          user_id: "",
          teacher_card_type_id: "",
          balance: 0,
          expire_date: "",
        },
        rules: {
          user_id: [{ required: true, message: "用户不能为空", trigger: "change" }],
          teacher_card_type_id: [{ required: true, message: "类型不能为空", trigger: "change" }],
          balance: [{ required: true, message: "充值金额不能为空", trigger: "change" }],
          expire_date: [{ required: true, message: "到期时间不能为空", trigger: "change" }],
        },
        userFilterLoading: false,
        users: [],
        teacherCardTypes: [],
        // 导入教师卡
        importTeacherCardData: [],
        importTeacherCardVisible: false,
        // 按类型一键充值
        dialogVisibleTypeRecharge: false,
        typeRechargeForm: {
          school_id: "",
          teacher_card_type_id: "",
          amount: 0,
          expire_date: "",
        },
        typeRechargeRules: {
          teacher_card_type_id: [{ required: true, message: "类型不能为空", trigger: "change" }],
          amount: [{ required: true, message: "充值金额不能为空", trigger: "change" }],
          expire_date: [{ required: true, message: "到期时间不能为空", trigger: "change" }],
        },
        // 按类型一键清零余额
        dialogVisibleTypeClearBalance: false,
        typeClearBalanceForm: {
          school_id: "",
          teacher_card_type_id: "",
        },
        typeClearBalanceRules: {
          teacher_card_type_id: [{ required: true, message: "类型不能为空", trigger: "change" }],
        },
        // 余额充值/回收
        dialogVisibleBalanceRechargeRecovery: false,
        balanceRechargeRecoveryForm: {
          school_id: "",
          id: "",
          type: "",
          amount: 0,
        },
        balanceRechargeRecoveryRules: {
          type: [{ required: true, message: "操作类型不能为空", trigger: "change" }],
          amount: [{ required: true, message: "操作金额不能为空", trigger: "change" }],
        },
      };
    },
    created() {
      this.getList();
      this.getTeacherCardTypeList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getList();
        this.getTeacherCardTypeList();
      },
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        this.listLoading = true;
        request({
          url: "/api/backend/teacherCard/list",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      getTeacherCardTypeList() {
        request({
          url: "/api/backend/teacherCardType/list",
          method: "get",
          params: {
            page: 1,
            limit: 1000000,
            school_id: this.school_id,
            is_on: 1,
          }
        }).then(response => {
          this.teacherCardTypes = response.data.data;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          school_id: "",
          user_id: "",
          teacher_card_type_id: "",
          balance: 0,
          expire_date: "",
        }
        this.btnLoading = false;
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.form.school_id = this.school_id;
        if (!this.form.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/teacherCard/save",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      userFilter(val) {
        this.userFilterLoading = true;
        request({
          url: "/api/backend/user/list",
          method: "get",
          params: {
            page: 1,
            limit: 10,
            // school_id: this.school_id,
            keyword: val,
          }
        }).then(response => {
          this.users = response.data.data;
        }).finally(()=>{
          this.userFilterLoading = false
        })
      },
      // 消费记录
      handleTeacherCardOrder(row) {
        this.$router.push(`/catered/teacherCardOrder?teacher_card_id=${row.id}`);
      },
      // 教师卡模板
      downloadTeacherCardTemplate() {
        let a = document.createElement("a");
        a.href = "/教师卡模板.xlsx";
        a.download = "教师卡模板.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      // 导入教师卡
      handleImportTeacherCard(file) {
        this.importTeacherCardData = [];
        file = file.raw;
        if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
          this.$message.error("上传文件格式不正确，请检查选择的文件");
          return;
        }
        excel2Json(file, 1).then((excelData) => {
          // 处理业务
          if (excelData.length <= 0) {
            this.$message.error("数据不能为空");
            return;
          }
          if (excelData.length > 300) {
            this.$message.error(`数据批量处理限制300以内,当前数量${excelData.length}`);
            return;
          }
          excelData.forEach((element, index) => {
            this.importTeacherCardData.push({
              index: element["序号"]||"",
              phone: (element["手机号码"]||"").toString(),
              teacher_card_type: element["类型"]||"",
              amount: element["充值金额"]||"",
              expire_date: element["到期时间"]||"",
            });
          });
          this.importTeacherCardVisible = true;
        });
      },
      importTeacherCardSaveData() {
        if (!this.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }
        this.btnLoading = true;
        for (let index = 0; index < this.importTeacherCardData.length; index++) {
          const item = this.importTeacherCardData[index];
          for (var key in item) {
            if (!item[key]) {
              this.$message({
                type: "error",
                message: "请先完善数据",
              });
              this.btnLoading = false;
              return;
            }
          }
        }
        request({
          url: "/api/backend/teacherCard/batchSave",
          method: "post",
          data: {
            school_id: this.school_id,
            data: this.importTeacherCardData,
          },
          timeout: 10 * 60 * 1000,
        }).then(() => {
          this.btnLoading = false;
          this.importTeacherCardVisible = false;
          this.$message({
            type: "success",
            message: "操作成功"
          });
          this.getList();
        }).catch(() => {
          this.btnLoading = false;
        });
      },
      // 按类型一键充值
      async handleTypeRecharge() {
        this.typeRechargeForm = {
          school_id: "",
          teacher_card_type_id: "",
          amount: 0,
          expire_date: "",
        };
        this.dialogVisibleTypeRecharge = true;
        this.$nextTick(() => {
          this.$refs["typeRechargeForm"].clearValidate() //清除校验结果
        })
      },
      saveDataTypeRecharge() {
        this.typeRechargeForm.school_id = this.school_id;
        if (!this.typeRechargeForm.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        this.$refs["typeRechargeForm"].validate((valid) => {
          if (valid) {
            this.btnLoading = true;
            request({
              url: "/api/backend/teacherCard/typeRecharge",
              method: "post",
              data: this.typeRechargeForm
            }).then(() => {
              this.dialogVisibleTypeRecharge = false;
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).finally(() => {
              this.btnLoading = false;
            });
          }
        });
      },
      // 按类型一键清零余额
      async handleTypeClearBalance() {
        this.typeClearBalanceForm = {
          school_id: "",
          teacher_card_type_id: "",
        };
        this.dialogVisibleTypeClearBalance = true;
        this.$nextTick(() => {
          this.$refs["typeClearBalanceForm"].clearValidate() //清除校验结果
        })
      },
      saveDataTypeClearBalance() {
        this.typeClearBalanceForm.school_id = this.school_id;
        if (!this.typeClearBalanceForm.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        this.$refs["typeClearBalanceForm"].validate((valid) => {
          if (valid) {
            this.btnLoading = true;
            request({
              url: "/api/backend/teacherCard/typeClearBalance",
              method: "post",
              data: this.typeClearBalanceForm,
              timeout: 10 * 60 * 1000,
            }).then(() => {
              this.dialogVisibleTypeClearBalance = false;
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).finally(() => {
              this.btnLoading = false;
            });
          }
        });
      },
      // 余额充值/回收
      async handleBalanceRechargeRecovery(row, type) {
        this.balanceRechargeRecoveryForm = {
          school_id: "",
          id: row.id,
          type: type,
          amount: 0,
        };
        this.dialogVisibleBalanceRechargeRecovery = true;
        this.$nextTick(() => {
          this.$refs["balanceRechargeRecoveryForm"].clearValidate() //清除校验结果
        })
      },
      saveDataBalanceRechargeRecovery() {
        this.balanceRechargeRecoveryForm.school_id = this.school_id;
        if (!this.balanceRechargeRecoveryForm.school_id) {
          this.$message({
            type: "warning",
            message: "请先选择学校"
          });
          return;
        }

        this.$refs["balanceRechargeRecoveryForm"].validate((valid) => {
          if (valid) {
            this.btnLoading = true;
            request({
              url: "/api/backend/teacherCard/balanceRechargeRecovery",
              method: "post",
              data: this.balanceRechargeRecoveryForm
            }).then(() => {
              this.dialogVisibleBalanceRechargeRecovery = false;
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).finally(() => {
              this.btnLoading = false;
            });
          }
        });
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
